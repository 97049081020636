<h1 style="text-transform: none" mat-dialog-title [innerHTML]="data.title" style="margin-left: 0px"></h1>
<div mat-dialog-content>
   <span *ngIf="data.description.split('#')?.length > 2">
    <span> {{data.description.split("#")[0]}}(<a target="_blank" [href]='data.description.split("#")[1]'>link</a>){{data.description.split("#")[2]}} </span> 
  </span>
  <span *ngIf="data.description.split('#')?.length == 1"> 
    <div style="margin-left: 15px">{{ data.description.substring(0, 144) }}</div>
    <div >
      {{ data.description.substring(144, data.description.length) }}
    </div>   
  </span>
  <!-- div if -->
</div>
<div mat-dialog-actions align="end">
  <!-- <button style="margin-right: 25px;" class="mat-raised-button mat-primary" [mat-dialog-close]="data.dialogData"
        cdkFocusInitial>Ok</button> -->
  <button
    mat-button
    style="margin-right: 25px;color:white"
    class="mat-button btn-primary button-ok"
    [mat-dialog-close]="data.dialogData"
  >
    OK
  </button>
</div>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
