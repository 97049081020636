<div class="left-nav" [ngClass]="{
  'expanded': !navCollapsed,
  'collapsed': navCollapsed
}">
  <div class="nav-header">
    <div class="menu-btn" (click)="toggleNavCollapsed()">
      <span class="material-icons">menu</span>
    </div>
    <div class="app-title">
      CBRE DATA PORTAL
    </div>
  </div>
  <div class="nav-list">
    <div *ngFor="let item of items" class="nav-item" (click)="routeLink(item.routeURL)">
      <app-nav-link class="nav-item-link" *ngIf="enabledNavItems(item,authService.CurrentUser)" [routeName]="item.routeName"
        [routeIcon]="item.routeIcon" [routeURL]="item.routeURL" [childRoutes]="item.childRoutes" [routeEmpty]="item?.routeEmpty"
        [menuCollapsed]="navCollapsed">
        
      </app-nav-link>

    </div>
  </div>
  <div class="log-out-btn" (click)="logout()" >
    <span class="material-icons mr-5">logout</span>
    <span style="font-size: 18px;" *ngIf="!navCollapsed">Logout</span>
  </div>
</div>
