<div class="nav-link-item" (click)="toggleNavDropdown()" #menu>
  <div class="icon-route">
    <div class="title">
      <div class="nav-icon">
        <div class="icon" [innerHTML]="routeIcon"></div>
        <div class="sub-menu-indicator" *ngIf="childRoutes && menuCollapsed">
          <span class="material-icons">chevron_right</span>
        </div>
      </div>
      <div class="nav-route" (click)="routeLink(routeName)" *ngIf="!menuCollapsed">
        <a style="color: #fff;" >
          {{ routeName }}</a>
      </div>

    </div>
    <div *ngIf="childRoutes && !menuCollapsed" class="nav-dropdown" #toggleButton >
      <span class="material-icons md-20"
            [ngClass]="{
              'down': !dropdownActive,
              'up': dropdownActive
            }">
        keyboard_arrow_down
      </span>
    </div>
  </div>
  <div *ngIf="childRoutes && dropdownActive && !menuCollapsed" class="uncollapsed child-routes active">

      <ul>
        <li [ngClass]="{'isEmpty': route?.routeEmpty}" *ngFor="let route of childRoutes;">
          <a (click)="routeSubRouteLink(route.routeURL)" style="display: contents;">
            <span class="nav-icon" [innerHTML]="route['routeIcon']"></span>
            <span>{{route.routeName}}</span></a>
        </li>
      </ul>
  </div>
</div>
<div *ngIf="childRoutes && collapsedHovered && menuCollapsed" class="collapsed child-routes active">
  <ul>
    <li [ngClass]="{'isEmpty': route?.routeEmpty}" *ngFor="let route of childRoutes;" (click)="routeSubRouteLink(route.routeURL)">
      <span class="nav-icon" [innerHTML]="route['routeIcon']"></span>
      <span>{{route.routeName}}</span>
    </li>
  </ul>
</div>
